// Init HTMX

(function (window) {
  'use strict';
  
  if (window.htmx) {
    window.htmx.config.requestClass = 'lazyloading';
  }
  
  document.addEventListener('pjax:success', function (pjaxEvent) {
    if (window.htmx) {
      var elements = document.body.querySelectorAll(pjaxEvent.selectors);
      for (var i = 0; i < elements.length; i++) {
        window.htmx.process(elements[i], true);
        window.htmx.trigger(elements[i], 'htmx:load', {});
      }
    }
  });
}(this));
